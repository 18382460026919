import baseURL from "@/utils/config";
import request from "@/plugins/axios"
// 查看物流/api/getNavigationList

export function getSearchExpressList2(data){
  return request({
      url:`${baseURL.api}/authapi/order/searchExpressList2`,
      method: "post",
      data,
  });
}
export function getLogisticsProduct(data){
  return request({
      url:`${baseURL.api}/authapi/order/searchExpressGoodsList`,
      method: "post",
      data,
  });
}
export function getLogisticsByOrderNo(data){
  return request({
      url:`${baseURL.javaApi}/authapi/rp/find-logistics`,
      method: "post",
      data
  });
}